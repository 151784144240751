
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { IUser } from "@/types/user";
import BarChart from "@/components/chart/BarChart.vue";
import DoughnutChart from "@/components/chart/DoughnutChart.vue";
import PieChart from "@/components/chart/PieChart.vue";
import HorizontalBarChart from "@/components/chart/HorizontalBarChart.vue";
import SupplierPivotList from "@/components/admin/list/SupplierPivotList.vue";
import CustomerPivotList from "@/components/admin/list/CustomerPivotList.vue";
import { IBooster, IBoosterListRequest } from "@/types/booster";
import { ChartData, ChartOptions } from "chart.js";
import { IError } from "@/types";
import * as palette from "google-palette";
// import Chart from "chart.js";

@Component({
  components: {
    BarChart,
    DoughnutChart,
    PieChart,
    HorizontalBarChart,
    SupplierPivotList,
    CustomerPivotList
  }
})
export default class extends Vue {
  @Getter("auth/me")
  public user!: IUser;

  @Action("booster/adminGetBooster")
  public getBooster!: (request: IBoosterListRequest) => boolean;

  @Getter("booster/booster")
  public booster!: IBooster;

  @Mutation("booster/clear")
  public clearBooster!: () => void;

  @Getter("error/error")
  public getError!: IError;

  public params: IBoosterListRequest = {
    per_page: 0,
    sales_staff: [],
    supplier: 0,
    search_span_start: "",
    search_span_end: "",
    shipment_status: 0,
    booster_supplier: []
  };
  public sales_staff_selected: [] = [];

  public loaded = false;
  public dates = ["", ""];
  public menu = false;
  public selectDate = "";
  public selected = [];
  public pivot1 = false;
  public pivot2 = false;
  public dashboard = true;
  public now_pages: number[] = [];

  public shipment_status_items = [
    { value: 0, text: "全て表示" },
    {
      value: Vue.prototype.$shipmentStatus.estimated_shipment,
      text: "出荷予定"
    },
    { value: Vue.prototype.$shipmentStatus.shipped, text: "出荷済み" }
  ];

  public monthlyCalcsData: ChartData = {};
  public profitRateData: ChartData = {};
  public sellingByUserData: ChartData = {};
  public profitByUserData: ChartData = {};
  public rcdOrdersCountByUserData: ChartData = {};
  public sellingBySupplierData: ChartData = {};
  public profitRateBySupplierData: ChartData = {};
  public quantityRankByProductData: ChartData = {};
  public profitRankByProductData: ChartData = {};

  public chartStyles = {
    height: "100px",
    width: "100px"
  };
  // public monthlyCalcsData: ChartData = {};

  public async created() {
    this.clearBooster();

    // 日付の取得と日付データの成形
    await this.paramsDateInsert();

    this.setChart();
  }

  public async setChart() {
    await this.getBooster(this.params);
    await this.createChartData();

    this.showCalc("Dashbord");
    this.now_pages = [0];

    this.loaded = true;
  }

  public createChartData() {
    this.setMonthlyCals(),
      this.setProfitRate(),
      this.setDataByUser(),
      this.setSellingBySupplier(),
      this.setProfitRateBySupplier(),
      this.setQuantityRankDataByProduct(),
      this.setProfitRankDataByProduct();
  }

  public paramsDateInsert() {
    const today = new Date();
    const start_date = new Date(today.getFullYear(), today.getMonth() - 5, 1);
    const fixed_start_date =
      start_date.getFullYear() +
      "-" +
      ("0" + (start_date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + start_date.getDate()).slice(-2);

    const end_date = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const fixed_end_date =
      end_date.getFullYear() +
      "-" +
      ("0" + (end_date.getMonth() + 1)).slice(-2) +
      "-" +
      end_date.getDate();

    this.params.search_span_start = fixed_start_date;
    this.params.search_span_end = fixed_end_date;

    const disp_start_date: string =
      start_date.getFullYear() +
      "-" +
      ("0" + (start_date.getMonth() + 1)).slice(-2);
    const disp_end_date: string =
      end_date.getFullYear() +
      "-" +
      ("0" + (end_date.getMonth() + 1)).slice(-2);

    this.dates = [disp_start_date, disp_end_date];
    this.dates.join(" 〜 ");
  }

  //総合情報
  public setMonthlyCals() {
    const monthly_labels = this.booster.monthly_calc.map(
      monthly_calc => monthly_calc.month
    );
    const monthly_buying_sum = this.booster.monthly_calc.map(
      monthly_calc => monthly_calc.buying_amount_sum
    );
    const monthly_selling_sum = this.booster.monthly_calc.map(
      monthly_calc => monthly_calc.selling_amount_sum
    );
    const monthly_shipments_count = this.booster.monthly_calc.map(
      monthly_calc => monthly_calc.shipments_count
    );
    const monthly_gross_profit = this.booster.monthly_calc.map(
      monthly_calc => monthly_calc.gross_profit
    );
    this.monthlyCalcsData = {
      labels: monthly_labels,
      datasets: [
        {
          data: monthly_shipments_count,
          type: "line",
          label: "出荷数",
          borderColor: "#FAB700",
          fill: false,
          lineTension: 0.5,
          yAxisID: "y-axis-2",
          datalabels: {
            align: "top",
            backgroundColor: "#F8DEC0",
            formatter: value => {
              return `${value.toLocaleString()}`;
            }
          }
        },
        {
          data: monthly_selling_sum,
          type: "line",
          label: "税抜売上",
          borderColor: "#66CCFF",
          fill: false,
          lineTension: 0.5,
          borderDash: [5, 5],
          yAxisID: "y-axis-3",
          datalabels: {
            align: "left",
            backgroundColor: "#D2F9FB",
            formatter: value => {
              return `${value.toLocaleString()}`;
            }
          }
        },
        {
          data: monthly_buying_sum,
          type: "bar",
          label: "仕入金額",
          backgroundColor: "#fcb4b4",
          yAxisID: "y-axis-1",
          datalabels: {
            formatter: value => {
              return `${value.toLocaleString()}`;
            }
          }
        },
        {
          data: monthly_gross_profit,
          type: "bar",
          label: "利益金額",
          backgroundColor: "#96Ed89",
          yAxisID: "y-axis-1",
          datalabels: {
            formatter: value => {
              return `${value.toLocaleString()}`;
            }
          }
        }
      ]
    };
  }

  public monthlyDataOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      //凡例設定
      display: true,
      position: "bottom",
      onClick: () => {
        return false;
      }
    },
    tooltips: {
      enabled: false
    },
    title: {
      display: true,
      text: "総合情報",
      position: "top",
      fontSize: 18,
      fontColor: "#000000",
      padding: 10
    },
    scales: {
      yAxes: [
        {
          display: false,
          stacked: true,
          id: "y-axis-1",
          ticks: {
            beginAtZero: true
          }
        },
        {
          id: "y-axis-2",
          type: "linear",
          stacked: false,
          position: "right",
          ticks: {
            beginAtZero: true,
            stepSize: 20,
            maxTicksLimit: 200
          }
        },
        {
          id: "y-axis-3",
          type: "linear",
          stacked: false,
          position: "left",
          ticks: {
            beginAtZero: true
          }
        }
      ],
      xAxes: [
        {
          stacked: true
        }
      ]
    },
    plugins: {
      datalabels: {
        font: {
          weight: "normal",
          size: 14
        },
        color: "#000000"
      }
    }
  };

  // 利益率-------------
  public setProfitRate() {
    const non_profit = 100 - this.booster.gross_profit_rate;
    this.profitRateData = {
      labels: ["利益率", "非利益率"],
      datasets: [
        {
          data: [this.booster.gross_profit_rate, non_profit],
          backgroundColor: ["#94d84c"],
          label: "利益率"
        }
      ]
    };
  }

  public profitRateOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      text: "利益率",
      fontSize: 18,
      fontColor: "#000000",
      padding: 10
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    }
  };

  // ------------------

  // 個人別データ -------------
  public setDataByUser() {
    const sale_name = this.booster.sales_calc.map(
      sale_calc => sale_calc.sales_name
    );

    //税抜売上グラフ
    const selling_amount_sum = this.booster.sales_calc.map(
      sale_calc => sale_calc.selling_amount_sum
    );

    // ユーザーごとに色の生成
    const colors = palette("mpn65", sale_name.length).map((hex: string) => {
      return "#" + hex;
    });

    this.sellingByUserData = {
      labels: sale_name,
      datasets: [
        {
          data: selling_amount_sum,
          label: "売上額(万円)",
          backgroundColor: colors,
          datalabels: {
            formatter: value => {
              const fixed_value = (value / 10000).toFixed(2).toString();
              const per = ((value / this.booster.selling_price) * 100).toFixed(
                1
              );
              return `${fixed_value}\n${per}%`;
            }
          }
        }
      ]
    };

    // 利益金額
    const gross_profit = this.booster.sales_calc.map(
      sale_calc => sale_calc.gross_profit
    );
    this.profitByUserData = {
      labels: sale_name,
      datasets: [
        {
          data: gross_profit,
          label: "利益額",
          backgroundColor: colors,
          datalabels: {
            formatter: value => {
              const fixed_value = (value / 10000).toFixed(2).toString();
              const per = ((value / this.booster.gross_profit) * 100).toFixed(
                1
              );
              return `${fixed_value}\n${per}%`;
            }
          }
        }
      ]
    };
  }

  // 税抜売上グラフ オプション
  public sellingByUserOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      text: "税抜売上(万円)",
      fontSize: 18,
      fontColor: "#000000",
      padding: 10
    },
    legend: {
      display: true,
      position: "right",
      onClick: () => {
        return false;
      }
    },
    tooltips: {
      enabled: false
    },
    scales: {
      yAxes: [
        {
          id: "y-axis-2",
          display: false,
          scaleLabel: {
            display: true
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        font: {
          weight: "bold",
          size: 16
        },
        color: "#000000"
      }
    }
  };

  // 利益グラフ オプション
  public profitByUserOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      text: "利益金額(万円)",
      fontSize: 18,
      fontColor: "#000000",
      padding: 10
    },
    legend: {
      display: true,
      position: "right",
      onClick: () => {
        return false;
      }
    },
    tooltips: {
      enabled: false
    },
    plugins: {
      datalabels: {
        font: {
          weight: "bold",
          size: 16
        },
        color: "#000000"
      }
    }
  };

  // ------------------

  // サプライヤ別データ---
  // 売上グラフ
  public setSellingBySupplier() {
    const sort = this.booster.supplier_calc.sort(function (a, b) {
      if (a.selling_amount_sum > b.selling_amount_sum) return -1;
      if (a.selling_amount_sum < b.selling_amount_sum) return 1;
      return 0;
    });
    const supplier_name = sort.map(
      supplier_calc => supplier_calc.supplier_name
    );
    const selling_amount_sum = sort.map(
      supplier_calc => supplier_calc.selling_amount_sum
    );
    this.sellingBySupplierData = {
      labels: supplier_name,
      datasets: [
        {
          data: selling_amount_sum,
          label: "売上額",
          backgroundColor: "#94d84c",
          datalabels: {
            formatter: value => {
              const per = ((value / this.booster.selling_price) * 100).toFixed(
                1
              );
              return `${value.toLocaleString()}\n${per}%`;
            }
          }
        }
      ]
    };
  }

  // 税抜売上グラフ オプション
  public sellingBySupplierOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      text: "サプライヤーの税抜売上一覧",
      fontSize: 18,
      fontColor: "#000000",
      padding: 10
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        font: {
          weight: "normal",
          size: 14
        },
        color: "#000000"
      }
    }
  };

  // 利益率グラフ
  public setProfitRateBySupplier() {
    const sort = this.booster.supplier_calc.sort(function (a, b) {
      if (a.gross_profit_rate > b.gross_profit_rate) return -1;
      if (a.gross_profit_rate < b.gross_profit_rate) return 1;
      return 0;
    });
    const supplier_name = sort.map(
      supplier_calc => supplier_calc.supplier_name
    );
    const gross_profit_rate = sort.map(
      supplier_calc => supplier_calc.gross_profit_rate
    );
    this.profitRateBySupplierData = {
      labels: supplier_name,
      datasets: [
        {
          data: gross_profit_rate,
          label: "売上率",
          backgroundColor: "#FF9933",
          datalabels: {
            formatter: value => {
              const fixed_value = value.toFixed(2);

              return `${fixed_value}%`;
            }
          }
        }
      ]
    };
  }

  public profitRateBySupplierOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      text: "サプライヤーの利益率一覧",
      fontSize: 18,
      fontColor: "#000000",
      padding: 10
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        font: {
          weight: "normal",
          size: 14
        },
        color: "#000000"
      }
    }
  };
  // ------------------

  // 商品別データ--------
  // 数量によるTop10商品
  public setQuantityRankDataByProduct() {
    const product_name = this.booster.product_calc.shipment_quantity.map(
      shipment_quantity => shipment_quantity.product_name
    );

    const quantity_sum = this.booster.product_calc.shipment_quantity.map(
      shipment_quantity => shipment_quantity.quantity_sum
    );

    this.quantityRankByProductData = {
      labels: product_name,
      datasets: [
        {
          data: quantity_sum,
          label: "数量",
          backgroundColor: "#528DD0",
          datalabels: {
            formatter: value => {
              return `${value.toLocaleString()}`;
            },
            font: {
              weight: "normal",
              size: 14
            },
            color: "#000000"
          }
        }
      ]
    };
  }
  public quantityRankByProductOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      text: "数量によるTop10商品",
      fontSize: 18,
      fontColor: "#000000",
      padding: 10
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    },
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  };

  // 利益金額によるTop10商品
  public setProfitRankDataByProduct() {
    const product_name = this.booster.product_calc.profit.map(
      profit => profit.product_name
    );

    const profit = this.booster.product_calc.profit.map(
      profit => profit.gross_profit
    );

    this.profitRankByProductData = {
      labels: product_name,
      datasets: [
        {
          data: profit,
          label: "金額",
          backgroundColor: "#528DD0",
          datalabels: {
            formatter: value => {
              return `${value.toLocaleString()}`;
            },
            font: {
              weight: "normal",
              size: 14
            },
            color: "#000000"
          }
        }
      ]
    };
  }
  public profitRankByProductOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      text: "利益金額によるTop10商品",
      fontSize: 18,
      fontColor: "#000000",
      padding: 10
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    },
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  };
  // ------------------

  // グラフ以外の処理がここから下
  get dateRangeText() {
    if (this.dates[0] > this.dates[1]) {
      this.dates = [this.dates[1], this.dates[0]];
    }
    return this.dates.join(" 〜 ");
  }

  // 年月日取得
  public setFixedDate() {
    if (this.dates[0] > this.dates[1]) {
      this.dates = [this.dates[1], this.dates[0]];
    }
    // 開始日
    let start_date = new Date(this.dates[0]);
    start_date = new Date(
      start_date.getFullYear(),
      start_date.getMonth() + 1,
      1
    );
    const fixed_start_date =
      start_date.getFullYear() +
      "-" +
      ("0" + start_date.getMonth()).slice(-2) +
      "-" +
      ("0" + start_date.getDate()).slice(-2);

    // 終了日
    let end_date = new Date(this.dates[1]);
    end_date = new Date(end_date.getFullYear(), end_date.getMonth() + 1, 0);
    const fixed_end_date =
      end_date.getFullYear() +
      "-" +
      ("0" + (end_date.getMonth() + 1)).slice(-2) +
      "-" +
      end_date.getDate();

    this.params.search_span_start = fixed_start_date;
    this.params.search_span_end = fixed_end_date;
  }

  public async salesStaffFilterRemove() {
    this.loaded = false;

    this.setChart();
  }

  public items = [
    {
      text: "Dashbord",
      icon: "mdi-chart-bar"
    },
    {
      text: "Pivot1",
      icon: "mdi-table"
    },
    {
      text: "Pivot2",
      icon: "mdi-table"
    }
  ];

  public showCalc(text: string) {
    if (text === "Dashbord") {
      this.dashboard = true;
      this.pivot1 = false;
      this.pivot2 = false;
    } else if (text === "Pivot1") {
      this.dashboard = false;
      this.pivot1 = true;
      this.pivot2 = false;
    } else if (text === "Pivot2") {
      this.dashboard = false;
      this.pivot1 = false;
      this.pivot2 = true;
    }
  }

  public clickRecalculation() {
    this.setChart();
  }
}
